.section__heading-wrapper{
  display: flex;
  justify-content: center;
  margin: 3.125rem 0 1rem;
  .section__heading {
    $img: 'decorators/about_squigle_left.png';
    position: relative;
    padding: 0 3.125rem;
    &::before,
    &::after {
      background: image-url($img) center center no-repeat;
      content: '';
      position: absolute;
      height: image-height($img);
      width: image-width($img);
      top: 0;
    }
    &::before {
      left: 0;
    }
    &::after {
      transform: scaleX(-1);
      right: 0;
    }
  }
}
