.nclood-nav-wrapper {
  position: relative;
  z-index: 1;
}

.header-main {
  position: relative;
}

.footer-main {
  padding: 30px 0;
  position: relative;
  z-index: 1;
  background-color: black;
  color: get-color('white');
}

.footer-main__share {
  @include display-flex(flex);
  @include align-items(center);
  @include flex-direction(column);


  padding: 0 ($column-gutter/2);
  text-align: center;
}

.footer-main__share__facebook,
.footer-main__share__support {
  @include font-Bitter();
  font-weight: 700;
  position: relative;
  font-size:0.875rem;
  line-height: 2.5714285714;
  border-top:2px solid #B99658;
  border-bottom:2px solid #B99658;
  border-radius: 11px;
  padding:7px 30px 5px 30px;
  margin: 0 0px 20px 0px;
  &:before,
  &:after {
    $img: 'common/footer_border.png';
    content:'';
    position: absolute;
    top:-2px;
    background:image-url($img) no-repeat left top;
    width:image-width($img);
    height:image-height($img);
  }
  &:before {
    left:1px;
  }
  &:after {
    right:1px;
    transform:scaleX(-1);
  }
  &:hover,
  &:focus {
    background-color: rgba(27,63,60,0.8);
  }
}

.footer-main__share__facebook {
  display: flex;
  padding:7px 22px 5px 36px;
  align-items:center;
  .txt {
    padding-right:8px;
  }

  nclood-social,
  .fb_iframe_widget,
  .fb_iframe_widget span {
    display: block;
  }
}

.footer-main__share__support {
  text-decoration: none;


}

.footer-main__links {
  margin-bottom: 30px;
}

.footer-main__legal {
  text-align: center;

  p {
    font: normal 12px Arial, Helvetica, sans-serif;
    margin-bottom: 3px;
  }
}

.loading-view {
  display: flex;
  justify-content: center;
  align-items: center;
  loading-indicator {
    --loading-indicator-color: #B99658;
  }
}

@media #{$medium-up} {
  .footer-main {
    padding: 95px 0 20px;
  }
  .footer-main__share {
    @include flex-direction(row);
    @include justify-content(center);
    @include align-items(center);
    margin-bottom: 30px;
  }
  .footer-main__share__facebook,
  .footer-main__share__support {
    margin:0 25px;
  }
}


