@keyframes spin {
  100% { transform:rotate(360deg); }
}

.cards .news-articles-wc {
  &__article {
    flex: 1 1 100%;
    max-width: 100%;
  }

  &__link {
    display: block;
    height: 100%;
    border: solid 1px #B99658;
    background: #F4EFE5;
    color: inherit;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    padding: 10px 12px;
    transition: background .4s ease;
    text-decoration: none;

    &:hover, &:focus {
      background: #fff;

      .news-articles-wc__image {
        transform: scale(1.05);
      }
    }
  }

  &__image-wrap {
    overflow: hidden;
  }

  &__image {
    transition: transform .5s ease;
    backface-visibility: hidden;
    transform: translateZ(0);
    width: 100%;
    aspect-ratio: 16/9;
  }

  &__content {
    padding: 10px;
  }

  &__date {
    font-family: 'Bitter', serif;
    color: #B99658;
    font-size: 0.875rem;
    line-height: 1.7142857143;
    margin: 0;
    font-style: italic;
  }

  &__title {
    margin: 0 0 5px 0;
    color: #42736E;
    font-family: 'Bitter', serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.3333333333;
    font-style: normal;
  }

  &__intro {
    font-family: 'Roboto', 'Open sans', 'Helvetica', sans-serif;
    color: #4A4A4A;
    font-size: 0.9375rem;
    margin: 0;
  }
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  padding:5px 0 40px 0;
  list-style: none;

  .page-home & {
    padding:0;
  }
}
a.card {
  text-decoration: none;
}
.card {
  border:solid 1px get-color('gold');
  background:get-color('white');
  cursor:pointer;
  display: block;
  padding:10px 12px;
  color:get-color('teal');
  box-shadow:0 0 5px 0 rgba(0,0,0,0.5);
  margin-bottom:10px;
  transition:background .4s ease;
  width:100%;
  transform: translateZ(1px);
  &:hover,
  &:focus {
    background:#fff;
    transform: translateZ(1px);
    backface-visibility: hidden;
    .card__header img {
      transform:scale(1.05) translateZ(1px);
      backface-visibility: hidden;
    }
  }
  img {
    width:100%;
  }
  h5 {
    @include font-Bitter();
    color:inherit;
    font-size:1.125rem;
    font-weight:700;
    line-height: 1.3333333333;
  }
}

.news-card__cta {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 88%;
  text-decoration: none;
  display: block;
  text-align: center;
  height: 46px;
  line-height: 46px;
  background-color: get-color("gold");
  font-weight: bold;
  position: absolute;
  bottom: 16px;
  
  @include font-Bitter();
  font-size: rem-calc(18);
  color: #032c2d;

}

.news-card__cta:hover {
  background-color: #d0af75;
}

.news-articles-wc__article {
  position: relative;
  p {
    font-style: initial;
  }
}

.card__header {
  overflow:hidden;
  backface-visibility: hidden;
  transform:translateZ(0);
}
.card__header img {
  display: block;
  transition:transform .5s ease;
  backface-visibility: hidden;
  transform:translateZ(0);
}

.news-articles-wc__content {
  margin-bottom: 56px;
}

.card__content {
  padding:10px;

  h5 {
    color: #42736E;
  }
}

.card--video {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:hover,
  &:focus {
    .card__icon--play {
      &:before {
        animation: spin 3s linear infinite;
      }
      &:after {
        transform:scale(1.1);
      }
    }
  }
}
.card--news {
  .card_content__date {
    @include font-Bitter();
    color:get-color('gold');
    font-size:0.875rem;
    line-height: 1.7142857143;
    margin:0;
    font-style: italic;
  }
  h5 {
    margin:0 0 5px 0;
  }
  p {
    @include font-Roboto();
    color:get-color('dark-grey');
    font-size:0.9375rem;
    margin:0;
  }
}
.card__header--icon {
  position: relative;
}
.card__icon {
  position: absolute;
  top:50%;
  left:50%;
}

.card__icon--play {
  width:77px;
  height:77px;
  margin-left:-38.5px;
  margin-top:-38.5px;
  border-radius:50%;
  background:rgba(55,113,121,.5);
  border:solid 3px rgba(55,113,121,.5);
  z-index:1;
  transform: translateZ(1px);
  &:before {
    $img:'common/ring-zelda.png';

    background:image-url($img) no-repeat left top;
    background-size:100%;
    content:'';
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
  }
  &:after {
    $img:'common/icon-play.svg';

    background:image-url($img) no-repeat left top;
    content:'';
    position: absolute;
    width:image-width($img);
    height:image-width($img) + 2px;
    top:50%;
    left:55%;
    margin-top:image-height($img)/2 * -1;
    margin-left:image-width($img)/2 * -1;
    transition:transform .3s ease;

  }
}

@media #{$small-only} {
  .cards > div {
    flex-basis:50%;
  min-width:50%;

    padding:0 5px;
  }

  .is-ie11 .cards > div {
      width: 50%;
      max-width: 50%;
  }
}

@media #{$xsmall-only} {
  .cards > div {
    flex-basis:100%;
    min-width:100%;
  }
}


@media #{$medium-up} {
  .cards {
    padding:0 10px;
  }
  .card {
    margin-bottom:23px;
  }

  .cards .news-articles-wc {
    &__article {
      flex: 0 0 48%;
    }
  }
}

@media #{$large-up} {
  .cards .news-articles-wc {
    &__article {
      flex-basis: 32%;
    }
  }
}


@include reduce-motion {
  .card--video {
    &:hover,
    &:focus {
      .card__icon--play {
        &::before {
          animation: none;
        }
      }
    }
  }
}
