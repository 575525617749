.page-about {
  h3,
  h4 {
    font-family: $font-main;
    font-size: 1.625rem;
    color: get-color("white");
  }
  h3 {
    font-size: 1.625rem;
  }

  html,
  body {
    overflow-x: hidden;
  }

  .section--glossary {
    padding-bottom: 60px;
  }

  //HERO
  .about-hero {
    $img: "about/header-about.jpg";
    background: image-url($img) top center no-repeat;
    background-size: cover;
    box-shadow: 0 2.5rem 5rem 0 get-color("shadow-50");
    height: 600px;
    position: relative;
    text-align: center;
    top: -47px; //compensate for navbar sitting attop

    &::after {
      $img: "home/hyrule_rule.png";

      background: image-url($img) bottom center;
      bottom: -44px;
      content: "";
      height: image-height($img);
      left: 0;
      margin-top: 100%;
      margin-top: image-height($img) / 2;
      position: absolute;
      width: 100%;
    }

    &__overlay {
      padding-top: 120px;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
      height: 100%;
    }
    .hero__header {
      background: rgba(0, 0, 0, 0);
      position: relative;
      text-align: center;
    }
    .sub-logo {
      bottom: 0;
      left: 0;
      padding-bottom: 3rem;
      position: absolute;
      width: 100%;
      span {
        display: block;
        font-size: rem-calc(48px);
        font-weight: 700;
        font-style: normal;
        line-height: 1;
        text-shadow: 1px 1px 8px get-color("black");
      }
    }
  }

  //DIETIES

  .dieties {
    @keyframes rotate-bg {
      from {
        transform: translateX(-50%) rotate(0deg);
      }
      to {
        transform: translateX(-50%) rotate(360deg);
      }
    }
    position: relative;
    &::after {
      animation: rotate-bg 60s linear infinite;
      background: image-url("about/bg-img-godesses.png") center center no-repeat;
      content: "";
      height: image-height("about/bg-img-godesses.png");
      left: 50%;
      position: absolute;
      top: -31rem;
      width: image-width("about/bg-img-godesses.png");
      z-index: -1;
    }
    &__copy {
      margin: calc(3rem - 43px) auto 3rem;
    }
    .header-copy {
      margin: 0;
      text-align: center;
    }
    .gods {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 2rem 0 0 0;
      margin: 0;
      text-align: center;
      list-style: none;

      .god-container {
        max-width: 366px;
        padding: 5px 45px;
        position: relative;
        width: 33.3%;

        @media #{$small-only} {
          width: 100%;
        }
        .goddess {
          display: block;
          padding: 100% 0 1rem;
          position: relative;

          h2 {
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
            text-transform: uppercase;
          }
          img {
            left: 50%;
            max-width: 257px;
            position: absolute;
            top: 30%;
            transform: translate(-50%, -50%);
            width: 100%;
          }
          h2 {
            color: get-color("gold");
            font-size: 2.25rem;
          }
          p {
            line-height: 1.8rem;
            padding: 0 1rem;
          }
        }
      }
    }
  }

  //TRIFORCE
  .triforce {
    padding: 0 0 5rem;
    .section__heading {
      margin-bottom: 21px;
    }

    &__text {
      padding: 0 10px;
      p {
        margin-top: 0;
        width: 100%;
      }
    }
  }

  //HISTORY
  .history {
    background-color: get-color("shadow-20");
    padding-bottom: 5rem;
    padding-top: 6rem;
    position: relative;

    &__sub-header {
      @media #{$small-only} {
        width: 100%;
        padding: 0 10px;
      }
    }
    // h2 {
    //   color: get-color('gold');
    //   font-family: $font-main;
    //   font-size: 2.625rem;
    //   font-weight: 700;
    //   text-align: center;
    // }
    &__games {
      padding: 0 4rem;
      .game {
        color: get-color("white");
        font-size: 1.125rem;
        margin-top: 4rem;
        text-align: center;

        img {
          border: 2px solid grey;
        }
      }
    }

    @media #{$medium-up} {
      min-height: 1043px;
    }
  }

  // GLOSSARY MODAL
  .modal__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0;
    // height: 100%;
    justify-content: space-around;
    box-shadow: none;
    background: transparent;
  }

  .glossary {
    opacity: 0;
  }

  &.modal__glossary {
    // blur page content when modal is open
    #wrap {
      filter: blur(10px);
      transition: filter 0.25s ease-in-out;
    }

    &.is-open {
      overflow: hidden;
    }

    .modal__wrapper {
      max-width: none;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .modal__overlay {
      background: rgba(0, 0, 0, 0.5);
    }

    .modal__content {
      width: 100%;
      background: transparent;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      background: transparent;
      opacity: 1;
      max-width: 100%;

      @media (min-width: 25.875em) and (max-width: 40.063em) {
        height: 100%;
      }
    }

    .glossary--detail-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      max-height: 100%;
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      align-self: center;
      justify-content: center;
      left: 0;
      right: 0;

      &.is-visible {
        opacity: 1;
      }

      @media #{$large-up} {
        width: calc(100% - 275px);
      }
    }

    .glossary {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      opacity: 1;

      @media (min-width: 25.875em) and (max-width: 60.938em) {
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
      }

      @media (max-width: 25.875em) {
        justify-content: space-around;
      }
    }

    .glossary--detail-content__wrap {
      width: 100%;
      max-width: 900px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-left: 15px;
      padding-right: 15px;

      p {
        font-size: 1rem;
      }

      .footer p {
        font-size: 0.75rem;
      }

      @media #{$medium-up} {
        width: 45%;
        justify-content: flex-start;
      }

      @media #{$large-up} {
        p {
          font-size: 1.125rem;
        }
      }

      @media (orientation: landscape) and (max-height: 320px) {
        padding-bottom: 50px;
      }
    }

    .glossary--nav {
      position: absolute;
      width: 100%;
      max-width: 1170px;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;

      @media (min-width: 25.875em) and (max-width: 54em) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        top: auto;
        transform: none;
        position: relative;
      }

      @media #{$large-up} {
        top: calc(50% - 45px);
      }

      @media (max-width: 25.823em) {
        top: 25%;
      }

      img {
        display: none;
        position: relative;
        bottom: -5px;
        transform: translateZ(0);
        backface-visibility: hidden;
        max-height: 100%;

        @media (min-width: 25.875em) {
          display: block;
          max-width: 75px;
        }
      }

      @media (orientation: landscape) and (max-height: 320px) {
        top: 60px;
        position: absolute;
        width: 100%;

        img {
          display: none;
        }
      }
    }

    .glossary--nav-prev,
    .glossary--nav-next {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;
      border: none;
      background: none;
      padding: 0;

      img {
        width: 75px;
        height: 84px;
        margin-left: 10px;
        margin-right: 10px;
        transform: translateY(-10px);
        position: relative;
      }

      &.glossary--nav-prev {
        left: 1%;
      }

      &.glossary--nav-next {
        right: 1%;
        flex-direction: row-reverse;
      }

      [class^="icon--arrow"] {
        transition: transform 0.15s ease-out;
      }

      &:hover,
      &:focus {
        .icon--arrow-left {
          transform: translateX(-5px);
        }

        .icon--arrow-right {
          transform: translateX(5px);
        }

        img {
          animation: flashImage 0.2s ease-in-out;
        }
      }

      @media (min-width: 25.875em) and (max-width: 54em) {
        position: relative;
      }

      @media #{$large-up} {
        margin-left: 0;
        margin-right: 0;
      }

      @media (orientation: landscape) and (max-height: 320px) {
        position: absolute;

        &.glossary--nav-prev {
          left: 0;
        }

        &.glossary--nav-next {
          right: 0;
        }
      }
    }

    .glossary--image {
      width: 100%;
      position: relative;

      img {
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        display: block;

        @media #{xlarge-up} {
          margin-right: 0;
        }
      }

      @media #{$medium-up} {
        width: 50%;
        justify-content: flex-end;
        padding: 30px;
      }

      @media (orientation: landscape) and (max-height: 480px) {
        height: 245px;
        width: auto;
        margin-top: 5px;
        padding-bottom: 10px;
      }

      @media (orientation: landscape) and (max-height: 320px) {
        height: 175px;
        width: auto;
        margin-top: 5px;
      }
    }
  }
}

.page-about {
  //MEDIA QUERIES
  @media #{$small-only} {
    .about-hero {
      height: 400px;
      .sub-logo span {
        font-size: 2rem;
      }
    }
    .goddess h2 {
      font-size: 2rem !important;
    }

    .modal__content {
      text-align: center;

      .glossary--image {
        width: 70%;
        margin-right: auto;
        margin-left: auto;
        display: block;

        img {
          max-width: 100%;
        }
      }
    }
  }
  @media #{$medium-only} {
    .about-hero {
      height: 450px;
    }
    .dieties {
      .gods {
        .god-container {
          padding: 5px 30px;
        }
      }
    }
  }
  @media #{$large-up} {
    .about-hero {
      height: 500px;

      &__overlay {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: 150px;
      }
    }
    .triforce__text {
      width: 80%;
    }

    #glossary--characters-list
      [class*="row-flex"]
      [class*="column-flex"]:last-child {
      padding-left: 11px;
      padding-right: 11px;
    }
  }
  @media #{$xlarge-up} {
    .about-hero {
      height: 650px;
    }
  }
}

@include reduce-motion {
  .page-about {
    .dieties::after {
      animation: none;
      transform: translateX(-50%);
    }

    &.modal__glossary {
      .glossary--nav-prev,
      .glossary--nav-next {
        &:hover {
          img {
            animation: none;
          }
        }
      }
    }
  }

  .triforce {
    &.vp-on-30 {
      .triforce--switcher--symbol,
      .triforce--switcher--characters {
        opacity: 1;
      }
    }

    .triforce--switcher--symbol,
    .triforce--switcher--characters {
      transform: none;
      transition: opacity 0.4s ease;
    }
  }
}
