// Typography
$base-font-size: 16px !default;
$base-line-height: 1.5 !default;
$rem-base: $base-font-size !default;

$font-main: 'Bitter', serif;
$font-secondary: 'Roboto', 'Open sans', 'Helvetica', sans-serif;

// Misc
$tran-main: cubic-bezier(.17,.67,.29,.97);
$main-radius: 1.8em;
$reduce-motion-query: '(prefers-reduced-motion: reduce)';
