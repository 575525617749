$reveal-pos: 20;
$reveal-tra: 0.4;

@mixin tm-reveal() {
  transition: transform #{$reveal-tra}s ease, opacity #{$reveal-tra}s ease;
  opacity: 0;
  transform: translateX(20px);
}

@mixin tm-pos() {
  &.vp-on-#{$reveal-pos} {
    @content;
  }
}

@mixin tm-show() {
  opacity: 1;
  transform: translateX(0);
}

.section--tl {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  overflow: visible;
  border-top: solid 1px get-color("gold");
  border-bottom: solid 1px get-color("gold");
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 1),
    0px -2px 0px 0px rgba(0, 0, 0, 1),
    0px 4px 0px 0px rgba(get-color("gold"), 0.6),
    0px -4px 0px 0px rgba(get-color("gold"), 0.6);

  &::after {
    @include retina-image("timeline/border-icon.png", 1);
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -49px;
  }
}

.section--timeline {
  //@include text-body();

  /* Headers */
  h1,
  h2,
  h3,
  h4,
  h5,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5 {
    margin: 0.65em 0 0.45em 0;
    font-style: italic;

    &:first-child {
      margin-top: 0;
    }
  }

  h1,
  .h1,
  h2,
  .h2 {
    @include font-Bitter();
    font-style: normal;
    font-weight: 700;
    color: get-color("gold");
    line-height: 1.18;
  }

  h2,
  .h2 {
    font-size: 2.5rem;
  }

  h3,
  .h3 {
    font-size: 2rem;
    line-height: 1.4;
  }

  h4,
  .h4 {
    font-size: 1.2222rem;
  }

  h5,
  .h5 {
    font-size: 1rem;
    color: get-color("gold");
  }

  main {
    position: relative;
  }

  a.button--boxed {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    margin: 0;
  }

  .wrapper {
    margin: 0 auto;
  }

  .wrapper--small {
    padding: 0;
    color: get-color("gold");

    p,
    h3,
    h4,
    h2 {
      margin: 0;
    }

    label.button {
      margin: 0 auto 20px;
      display: table;
      width: 100%;
      max-width: 300px;
    }

    .description--intro {
      margin-bottom: 40px;

      img {
        max-width: 100%;
        margin: 0 auto 25px;
      }
    }

    .description__title {
      margin-bottom: 30px;
    }

    #small-timeline-label {
      display: none;

      &:checked {
        ~ .timeline--main {
          display: block;
        }

        ~ label[for="small-timeline-label"] {
          display: none;
        }
      }
    }

    .timeline--main {
      display: none;
    }
  }

  .timeline {
    position: relative;
  }

  .timeline-border {
    position: relative;
    padding-top: 20px;
    padding-bottom: 5px;

    &::before {
      transform: scaleY(0);
      transform-origin: center top;
      transition: transform 2s ease;
      content: "";
      position: absolute;
      top: 0;
      left: 18px;
      height: 100%;
      width: 2px;
      background-color: get-color("gold");
    }

    @include tm-pos() {
      &::before {
        transform: scaleY(1);
      }
    }
  }

  .timeline-border--last {
    padding-bottom: 1px;

    &::before {
      height: calc(100% + 17px);
    }
  }

  .timeline--main {
    max-width: 335px;
    margin: 0 auto;
  }

  .timeline-events,
  .timeline__game {
    position: relative;
    margin-left: 55px;

    &::before {
      @include tm-reveal();

      content: "";
      position: absolute;
      top: 10px;
      right: calc(100% + 15px);
      width: 20px;
      height: 2px;
      background-color: get-color("gold");
    }

    @include tm-pos() {
      &::before {
        @include tm-show();
      }
    }
  }

  .timeline-title {
    color: get-color("black");
    padding: 30px 40px 10px;
    width: 100%;
    text-align: center;
    line-height: 1.09;
    font-style: normal;
    font-size: 1.222rem;
    background: get-color("gold") image-url("decorations/triforce-swirl.svg")
      center 10px no-repeat;
    position: relative;

    + p {
      display: none;
    }
  }

  .timeline-era {
    font-style: italic;
    color: get-color("gray");
  }

  .timeline-events {
    margin-bottom: 40px;

    @include tm-pos() {
      .timeline-event {
        @include tm-show();
      }
    }
  }

  .timeline-event {
    @include tm-reveal();

    transition-delay: 0.1s;
    font-style: italic;
    color: get-color("gold");
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .timeline__game {
    margin-bottom: 40px;

    &::before {
      top: 15px;
    }

    .timeline-events {
      margin-left: 0;
      margin-bottom: 0;

      &::before {
        display: none;
      }

      .timeline-event {
        transition-delay: 0.3s;

        @for $i from 2 through 4 {
          &:nth-child(#{$i}) {
            transition-delay: #{($i + 2) / 10}s;
          }
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    .timeline-event {
      border-bottom: solid 1px get-color("gray");
      padding-bottom: 10px;
      margin-bottom: 7px;
    }

    @include tm-pos() {
      .timeline__game-title,
      .timeline__game-thumbnail,
      .timeline-event {
        @include tm-show();
      }
    }
  }

  .timeline__game-title {
    @include tm-reveal();

    transition-delay: 0.1s;
    font-size: 1.375rem;
    margin-bottom: 7px;
  }

  .timeline__game-thumbnail {
    @include tm-reveal();

    transition-delay: 0.2s;
    display: block;
    max-width: 100%;
    margin-bottom: 8px;
  }

  .split {
    padding-top: 30px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      height: 2px;
      width: calc(75% - 12px);
      right: calc(25% - 6px);
      background-color: get-color("gold");
    }
  }

  .split__option {
    display: none;

    > .timeline:first-child,
    > .split:first-child {
      margin-top: 25px;
    }
  }

  .split__option-toggle {
    display: none;

    &:checked {
      + .split__option {
        display: block;
      }
    }
  }

  .split__option-title-wrapper {
    display: flex;
    justify-content: center;

    .split__option-title {
      margin-right: 20px;
      width: 50%;

      + .split__option-title {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .timeline-screen--intro .button,
  .split__option-title {
    transition: background-color 0.2s ease;
    color: get-color("gold");
    background-color: get-color("black");
    border: solid 1px get-color("gold");
    max-width: 160px;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.2;
    padding: 10px 0;
    position: relative;

    &.is-active {
      background-color: rgba(get-color("gold"), 0.25);

      &::after {
        display: block;
      }
    }

    &:focus {
      outline: none;
    }

    &::before,
    &::after {
      content: "";
      height: 30px;
      left: 50%;
      margin-left: -1px;
      width: 2px;
      background-color: get-color("gold");
      position: absolute;
    }

    &::before {
      bottom: 100%;
    }

    &::after {
      top: 100%;
      display: none;
    }
  }

  .split--triumph {
    &::before {
      transform: translateX(-50%);
      left: 50%;
      right: auto;
      width: calc(50% + 11px);
    }
  }

  // Removing stuff for small that is just used to parse in JS
  .wrapper--small {
    .timeline-era,
    .timeline__game-description,
    .timeline__game-logo,
    .split-description,
    .split-title,
    .timeline-placeholder,
    .hide-br {
      display: none;
    }
  }
}

@media #{$medium-up} {
  $transition: 0.4s;

  .section--tl {
    padding-bottom: 0;
    margin-top: 0;
  }

  .section--timeline {
    main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(100vh - 85px);
    }

    .wrapper--big {
      padding: 40px 0 17px;
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    .timeline-wrapper {
      padding: 20px 0;
      background-color: #062b31;
      position: relative;
      transition: opacity 0.6s ease, transform 0.6s ease, height 0.6s ease;
      opacity: 1;
    }

    .timeline-wrapper-canvas {
      overflow: hidden;
      position: relative;

      canvas {
        display: block;
      }
    }

    .timeline-instructions {
      padding: 0 10px;
      text-align: center;
      color: #7da1a5;
      font-size: 1rem;
      font-family: Roboto, "Open Sans", sans-serif;
    }

    .timeline-nav {
      @include image-replace();
      @include linear-gradient(to left, transparent, #031d22);

      appearance: none;
      border: none;
      padding: 0;
      width: 100px;
      height: 100%;
      transition: transform 0.4s 0.6s ease, opacity 0.4s 0.6s ease;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;

      &::after {
        @include retina-image("timeline/timeline-arrow.svg", 1);
        @include center-absolute(false, "timeline/timeline-arrow.svg", false);
        content: "";
        transition: transform 0.3s ease;
      }

      &:hover,
      &:focus {
        &::after {
          transform: translateX(-10px);
          opacity: 0.8;
        }
      }

      &[disabled] {
        cursor: auto;

        &::after {
          opacity: 0.25;
        }

        &:hover,
        &:focus {
          &::after {
            transform: none;
            opacity: 0.25;
          }
        }
      }
    }

    .timeline-nav--next {
      @include linear-gradient(to right, transparent, #031d22);
      left: auto;
      right: 0;

      &[disabled]:hover::after,
      &[disabled]:focus::after,
      &::after {
        transform: scaleX(-1);
      }

      &:hover,
      &:focus {
        &::after {
          transform: scaleX(-1) translateX(-10px);
        }
      }
    }

    .timeline-screens {
      position: relative;
      max-width: $row-width;
      margin: 0 auto 20px;

      .hide-br {
        display: none;
      }
    }

    .timeline-screen {
      transition: opacity 0.2s ease;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      opacity: 0;
      pointer-events: none;

      &.is-active {
        opacity: 1;
        pointer-events: all;
        z-index: 1;

        .timeline-screen__title,
        .timeline-screen__description,
        .timeline-screen--timeline__game-thumbnail,
        .timeline-screen--timeline__game-title,
        .timeline-screen--game__description-wrapper img,
        .timeline-screen--game__description,
        .timeline-screen--game__link,
        .timeline-screen--game__img {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }

    .timeline-screen__title,
    .timeline-screen__description,
    .timeline-screen--timeline__game-thumbnail,
    .timeline-screen--timeline__game-title,
    .timeline-screen--game__description-wrapper img,
    .timeline-screen--game__description,
    .timeline-screen--game__link,
    .timeline-screen--game__img {
      transition: transform 0.6s ease, opacity 0.4s ease;
      opacity: 0;
    }

    .timeline-screen__title {
      transform: translateY(-25%);
      font-size: 2.5rem;
      line-height: 1.185;
      text-align: center;
      margin: 0 auto 60px;
      // max-width: 60%;
      max-width: 480px;
    }

    .timeline-screen__subtitle {
      font-size: 2.25rem;
      color: get-color("white");
      letter-spacing: 1px;
    }

    .timeline-screen__description {
      transform: translateY(25%);
      transition-delay: 0.1s;
      font-size: 1.375rem;
      letter-spacing: 0.02em;
      line-height: 1.72em;
      max-width: grid-calc(8, $total-columns);
      margin: 0 auto 20px;
      opacity: 0;
      color: get-color("white");
      font-family: $font-main;
      font-style: italic;
    }

    .timeline-screen__split-options {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      button {
        padding: 10px 30px;
        border: solid 2px get-color("gold");
        background: none;
        color: get-color("gold");
        transition: background-color 0.2s ease;

        &:hover,
        &:focus {
          background-color: #193c38;
        }
      }
    }

    // Timeline screens
    .timeline-screen--intro {
      .button {
        border-width: 3px;
        padding: 20px 40px;
        max-width: none;

        &::before {
          display: none;
        }

        &:hover,
        &:focus {
          background-color: #2d2410;
        }
      }

      .timeline-screen__subtitle {
        margin-top: -5px;
      }
    }

    .timeline-screen--timeline {
      flex-direction: row;
    }

    .timeline-screen--timeline__games {
      justify-content: space-around;
      flex-wrap: nowrap;
      padding: 0 10px;
      list-style: none;

      _:-ms-fullscreen,
      :root & {
        margin: 0 0 40px;
      }

      li {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        @for $i from 1 through 8 {
          &:nth-child(#{$i}) {
            $delay: $i/10;
            .timeline-screen--timeline__game-thumbnail {
              transition-delay: #{$delay}s;
            }

            .timeline-screen--timeline__game-title {
              transition-delay: #{$delay + 0.2}s;
            }
          }
        }
      }
    }

    .timeline-screen--game__description {
      font-family: $font-main;
      font-style: italic;
      color: get-color("white");
      font-size: 1rem;
    }

    .timeline-screen--timeline__game {
      background: none;
      border: none;
      max-width: 180px;
      padding: 0;
      text-decoration: none;
      -webkit-transform: translate3d(0, 0, 0);

      _:-ms-fullscreen,
      :root & {
        flex-basis: 100%;
      }

      &:hover,
      &:focus {
        .timeline-screen--timeline__game-thumbnail {
          opacity: 0.8;
        }

        .timeline-screen--timeline__game-title {
          span {
            color: get-color("gold");
          }
        }
      }
    }

    .timeline-screen--timeline__game-thumbnail {
      max-width: 100%;
      display: block;
      border: solid 2px get-color("gold");
      transform: translateY(25%);
    }

    .timeline-screen--timeline__game-title {
      transform: translateY(50%);
      font-size: 0.9rem;
      text-align: center;
      margin-top: 13px;

      span {
        transition: color 0.2s ease;
        color: get-color("white");
      }
    }

    // Game screens
    .timeline-screen--game {
      align-items: center;
      min-height: 360px;
    }

    .timeline-screen--game__description-wrapper img,
    .timeline-screen--game__description,
    .timeline-screen--game__link {
      transform: translateX(-25%);
    }

    .timeline-screen--game__description-wrapper {
      img {
        max-width: 100%;
        display: block;
        margin: 0 auto 5px;
      }
    }

    .timeline-screen--game__description {
      transition-delay: 0.1s;
    }

    .timeline-screen--game__img {
      transform: translateX(25%);

      img {
        display: block;
        width: 100%;
        border: solid 2px get-color("gold");
      }
    }

    .timeline-screen--game__link {
      display: block;
      transition-delay: 0.2s;
      color: get-color("gold");
      font-size: 1.125rem;
      text-decoration: none;

      span {
        transition: color 0.2s ease;
      }

      &:hover,
      &:focus {
        span {
          color: #f1cf85;
          //color: rgba(27, 63, 60, 0.9);
        }
      }
    }

    // Split screen
    .timeline-screen--split {
      .timeline-screen__title {
        margin-bottom: 30px;
      }
    }
  }
}

@media #{$screen} and (min-width: 768px) {
  .section--timeline {
    main {
      min-height: calc(100vh - 72px);
    }
  }
}

@media #{$large-up} {
  .section--timeline {
    .wrapper--big {
      padding: 0 0 40px;
    }
  }
}

@include reduce-motion {
  .timeline-screen__title,
  .timeline-screen__description,
  .timeline-screen--timeline__game-thumbnail,
  .timeline-screen--timeline__game-title,
  .timeline-screen--game__description-wrapper img,
  .timeline-screen--game__description,
  .timeline-screen--game__link,
  .timeline-screen--game__img {
    transition-property: opacity 0.4s ease;
    transform: none;
  }
}
