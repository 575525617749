.modal {
  z-index: 101;
}

.modal__wrapper {
  border: solid 0px transparent;
}

.modal__content {
  padding: 10px;
}

.modal--retailer {
  .modal__wrapper {
    width: 90%;
  }

  .modal__content {
    height: 70vh;
    padding: 10px;
    background: white;
  }
}

.modal__close {
  overflow: hidden;
  text-indent: 0;

  span {
    position: relative;
    left: 200%;
  }
}

.modal--video,
.modal__glossary {
  .modal__content {
    padding: 2px;
    background: get-color("gold");
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }
}

.modal--video,
.modal__glossary,
.modal--news {
  .modal__close {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    padding: 5px;
    background: rgba(39, 79, 84, 0.6);
    text-indent: -9999px;
    position: absolute;
    right: -13px;
    top: -22px;
    z-index: 20;

    &:before {
      $img: "common/icon-x.svg";
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      background: image-url($img) no-repeat left top;
      background-size: 100%;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      margin-left: -8px;
    }
    
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      border: solid 2px #d1a63b;
      width: 36px;
      height: 36px;
      margin-top: -18px;
      margin-left: -18px;
      border-radius: 50%;
    }
  }
}

.modal__glossary {
  z-index: 101;

  body[data-useragent*="re:11"] &,
  body[data-useragent*="Trident/7"] & {
    background: image-url("ie-transparent-bg.png");
  }

  .modal__overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .modal__wrapper {
    max-width: none;
    top: 0;
    transform: translate(-50%, 0);
    // height: 100%;
    overflow: auto;

    @media #{$small-only} {
      max-width: calc(100% - 15px);
      overflow-x: hidden;
    }
  }

  .modal__close {
    top: 1%;
    right: 1%;
  }

  .modal__content {
    background: none;
    padding: 10% 0 0;

    @media #{$small-only} {
      padding-top: 85px;
    }

    #glossary--characters-list.transition--out {
      opacity: 1;
      height: auto;
      overflow: visible;

      .glossary--view-all-close {
        display: block;
        right: 20px;
        top: 20px;
        position: absolute;
        text-decoration: none;
      }
    }
  }

  @media #{$small-only} {
    .modal__wrapper {
      max-width: 100%;
      padding: 0;
    }

    .modal__content {
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  @media #{$large-up} {
    .modal__wrapper {
      max-width: 900px;
    }
  }

  @media screen and (min-height: 500px) {
    .modal--retailer {
      .modal__content {
        height: 440px;
        padding: 20px 10px;
      }
    }
  }

  @media #{$medium-up} and #{$retina} {
    .modal__wrapper {
      border-image: image-url("global/modal-border_2x.png") 60 68 62 66 fill
        repeat;
    }
  }
}
