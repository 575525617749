.hero {
  background-repeat:no-repeat;
  background-position:center bottom;
  color:get-color('white');
  position: relative;

  &:before {
    content:'';
    background-repeat:no-repeat;
    background-position:center bottom;
    position: absolute;
    height: 145%;
    width:100%;
    top:0;
    left:0;

  }
  .row-flex {
    align-items: center;
  }
  h1 {
    margin-top:20px;
    @include font-Bitter();
    color:get-color('gold');
    font-size:2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  p {
    @include font-Bitter();
    line-height:1.5555555556;
    font-size:1rem;
    font-style: italic;
    margin-bottom:0;
    padding-bottom:0.8em;
  }
}

.hero__content {
  text-align: center;
  padding-top: 34px;
  margin-top:-58px;
  position: relative;
  z-index:1;
  background:rgba(0,0,0,0.3);
}
.hero__image {
  position: relative;
}
.hero__image__wrapper {
  position: relative;
  left:5%;
  max-width:290px;
  margin-left:auto;
  margin-right:auto;
  img {
    max-width:100%;
  }
}



@media #{$medium-up} {
  .hero {
    h1 {
      font-size:2.3333333333rem;
    }
    p {
      font-size:1.125rem;
    }
  }
  .hero__image {
    order:2;
    // margin-bottom:-20%; not working in firefox
  }
  .hero__image__wrapper {
    margin-bottom:-40%;
    padding-top:60px;
    max-width:none;
  }
  .hero__content {
    background:none;
    padding-top:50px;
    order:1;
    margin-top:0;
    &:before {
      content:none;
    }
    .triforce-border {
      display: none;
    }
  }
}
