.logo {
  text-align: center;
  span {
    @include font-Bitter();
    display: inline-block;
    font-size:1.0625rem;
    font-style: italic;
    line-height: 2.5714285714;
    text-align: center;

    position: relative;
    &:before,
    &:after {
      $img:'decorators/sword_decorator.png';
      background:image-url($img) no-repeat left top;
      content:'';
      height:image-height($img);
      margin-top:image-height($img)/2 * -1;
      position:absolute;
      top:50%;
      width:image-width($img);
    }
    &:before {
      background:image-url('decorators/sword_decorator-l.png') no-repeat left top;
      filter: drop-shadow(1px 1px 4px rgba(0,0,0,0.74));
      right:106%;
    }
    &:after {
      filter: drop-shadow(1px 1px 4px rgba(0,0,0,0.74));
      left:106%;
    }
  }
  img {
    display: block;
    filter: drop-shadow(1px 1px 8px #000000);
    margin:0 auto;
    max-width:100%;
    width:277px;
  }
}
.logo--hero {
  display: none;
  margin-top:25px;
}

.logo--home,
.logo--about {
  span {
    font-size: 0.8125rem;
    text-shadow: 1px 1px 6px #000000;
  }
  img {
    max-width:223px;
    width:100%;
    margin:0 auto;
  }
}

@media #{$medium-up} {
  .logo--hero {
    display: block;
    position: absolute;
    min-width:170px;
    margin-top:0;
    right:20px;
    top:62px;
    width:32.42835596%;
    z-index:5;
  }
  .logo {
    span {
      font-size:0.7777777778rem;
      padding-bottom:4px;
    }
  }
  .logo--home,
  .logo--about{
    span {
      font-size:1rem;
    }
    img {
      max-width:280px;
    }
  }
  .logo--videos,
  .logo--news {
    span {
      font-size: 0.85rem;
    }
  }
}

@media #{$large-up} {
  .logo--home {
    img {
      max-width:352px;
    }
  }
  .logo--about {
    span {
      font-size: 1.125rem;
    }
    img {
      max-width: 352px;
    }
  }
}

@media #{$xlarge-up} {
  .logo--hero {
    right:0;
  }
  .logo--home,
  .logo--about {
    span {
      font-size:1.625rem;
    }
    img {
      max-width:452px;
    }
  }
}

