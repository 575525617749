.hover {
  height: 100%;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s opacity;
  width: 140%;
  div {
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .glowbase { background-image: image-url('common/bglight-base.png')}
  .glow1 {
    animation: 3s fade infinite;
    background-image: image-url('common/bglight-rays1.png')
  }
  .glow2 {
    animation: -1.5s 3s fade infinite;

    background-image: image-url('common/bglight-rays2.png')
  }
  .dust1 {
    animation: -0.666s 2s fade infinite;
    background-image: image-url('common/bglight-dust1.png')
  }
  .dust2 {
    animation: -1.333s 2s fade infinite;
    background-image: image-url('common/bglight-dust2.png')
  }
  .dust3 {
    animation: 2s fade infinite;
    background-image: image-url('common/bglight-dust3.png')
  }
}
