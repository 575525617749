.news-single--content .news-article-wc {
  &__date {
    font-family: "Bitter", serif;
    color: #b99658;
    font-size: 1.25rem;
    font-style: italic;
    text-align: center;
  }

  &__loading {
    text-align: center;
    position: relative;
    top: 40px;
    loading-indicator {
      --loading-indicator-color: #b99658;
    }
  }

  h1 {
    font-family: "Bitter", serif;
    font-size: 1.625rem;
    line-height: 1.5;
  }
}

.page-news-article {
  .hero {
    &:before {
      $img: "news/bg-img-hyrule.png";
      background-image: image-url($img);
      background-position: center top;
    }
  }

  .section--news-list {
    position: relative;
    margin-top: 50px;
    margin-bottom: 63px;
    h2 {
      @include font-Bitter();
      color: get-color("white");
      margin-bottom: 35px;
      font-size: 1.625rem;
    }
    .cta-button {
      max-width: 472px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      display: block;
      margin-top: 38px;
    }
    .cards {
      margin-bottom: 2em;
    }
  }

  .news-single--content p {
    line-height: 1.5rem;
  }
}

.esrb-container {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  font-size: 14px;
  @include font-Roboto();
  line-height: 1.6em;
  font-weight: bold;
}

.youtube-embed {
  aspect-ratio: 16 / 9;

  iframe {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

@media #{$medium-up} {
  .page-news-article {
    .hero {
      .hero__image__wrapper {
        margin-top: -8%;
      }
      .hero__content .cta-btn {
        margin-top: -25%;
      }
      .cta-btn {
        max-width: 325px;
      }
      .logo {
        span {
          font-size: 0.85rem;
          &:before,
          &:after {
            $img: "decorators/sword_decorator.png";
            background-size: 80%;
            height: image-height($img) * 0.8;
            margin-top: (image-height($img) / 2 * -1) * 0.8;
            width: image-width($img) * 0.8;
          }
          &:before {
            right: 101%;
          }
        }
      }
    }
    .news-single {
      &:before {
        content: "";
      }
      .triforce-border {
        display: block;
      }
      .news-single--header {
        padding-top: 0;
      }
    }

    .section--news-list {
      .cta-btn {
        max-width: 325px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media #{$large-up} {
  .page-news-article {
    .hero {
      .logo--hero {
        width: 32.42835596%;
      }
    }
  }
}
