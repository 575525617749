//BORDERS

.section__border-gradient {
  &::before,
  &::after {
    content: "";
    height: 1px;
    background: linear-gradient(to right, get-color('black') 0%, get-color('light-gold') 50%, get-color('black') 100%);
    position: absolute;
    left: 0;
    width: 100%;
  }

  &::before{
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}
.section__border-gradient--wide {
  &::before,
  &::after {
    background: linear-gradient(to right, get-color('black') 0%, get-color('light-gold') 10%,get-color('light-gold') 90%, get-color('black') 100%);
  }
}
.section__border-gradient--toponly {
  &::after {
    content:none;
  }
}

.triforce-border {
  &::before,
  &::after{
    background: image-url('common/triangle-border.png') center center repeat-x;
    content: '';
    position: absolute;
    width: 500%;
    height: 6px;
  }
  &::before {
    top: 3px;
    animation: triangleAnimationLeft 120s linear infinite;
  }

  &::after {
    bottom: 3px;
    animation: triangleAnimationRight 120s linear infinite;
  }
}

.triforce-border--toponly {
  &:after {
    content:none;
  }
}

.amiibo__card__triforce {
  position: absolute;
  bottom: 0;
  left: calc(50% - 5px);
  border-style: solid;
  border-width: 0 5px 8.7px 5px;
  border-color: transparent transparent rgba(185, 150, 88, 0.2) transparent;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: -18px;
    border-style: solid;
    border-width: 0 5px 8.7px 5px;
    border-color: transparent transparent rgba(185, 150, 88, 0.2) transparent;
  }

  &::after {
    position: absolute;
    content: '';
    left: -10px;
    bottom: -18px;
    border-style: solid;
    border-width: 0 5px 8.7px 5px;
    border-color: transparent transparent rgba(185, 150, 88, 0.2) transparent;
  }
}


//TEXT ORNAMENTS
.header-ornament {
  position: relative;
  &::before,
  &::after {
    background-repeat: no-repeat;
    background-position: center center;
    content: '';
    width: 30px;
    height: 9px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    @media #{$small-only} {
      content: none;
    }
  }
  &::before {
    background: image-url('home/ornament_left.png') center center no-repeat;
    margin-right: 1rem;
  }
  &::after {
    background: image-url('home/ornament_right.png') center center no-repeat;
    margin-left: 1rem;
  }
}

.sword-decorator {
  position: relative;
  &::before,
  &::after {
    background: image-url('decorators/sword_decorator.png') center center no-repeat;
    content: '';
    width: 27px;
    height: 15px;
    display: inline-block;
  }

  &::before {
    margin-right: 1.5rem;
  }
  &::after {
    margin-left: 1.5rem;
    transform: scaleX(-1);
  }
}

@keyframes triangleAnimationRight {
  from {
    transform: translateX(-1955px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes triangleAnimationLeft {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(-1955px);
  }
}


@include reduce-motion {
  .triforce-border {
    &::before,
    &::after {
      animation: none;
    }
  }

}
