.featured-games__list {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  width: 100%;

  a {
    text-decoration: none;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    flex-wrap: nowrap;
    width: auto;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media #{$large-up} {
    overflow-x: visible;

    .wrapper {
      flex-wrap: wrap;
    }
  }
}

.featured-games__item {
  z-index: 19;
  position: relative;
  transition: transform 0.15s ease-in-out;
  text-align: center;
  @include font-Roboto();
  font-size: rem-calc(14);
  margin: 0 auto 20px;
  width: 86.5vw;
  text-align: left;

  @media #{$medium-only} {
    width: 30.5vw;
  }

  @media #{$medium-up} {
    margin: 0 0 10px 0;
  }

  @media #{$large-up} {
    margin: 0;
  }
}

.featured-games__item-wrap {
  display: block;
  background-color: #fff;
  margin-left: 3px;
  margin-right: 3px;
  padding-top: 1px;

  @media #{$large-up} {
    background-color: transparent;
    margin: 0;
  }
}

.featured-games__image {
  width: 100%;
  margin-top: 4px;
  transform: scale(0.95);
  transition: transform 0.1s ease-out;

  img {
    width: 100%;
    display: block;
  }

  @media #{$large-up} {
    border: 2px solid get-color("gold");
  }
}

.featured-games__platform-tag-wrap {
  color: get-color("gold");
  padding: 8px 0 0px 8px;

  @media #{$large-up} {
    padding: 8px 0 8px 8px;
  }
}

.featured-games__hidden {
  background: #fff;
  z-index: 20;
  width: 100%;
  padding: 8px;
  position: relative;
  top: 0;
  text-align: left;
}

.featured-games__title {
  color: get-color("teal");
  @include font-Bitter();
  font-size: rem-calc(18);
  font-weight: bold;
}
.featured-games__cta {
  text-decoration: none;
  display: block;
  text-align: center;
  height: 46px;
  line-height: 46px;
  background-color: get-color("gold");
  width: 100%;
  margin-top: 16px;
  font-weight: bold;
  @include font-Bitter();
  font-size: rem-calc(18);
  color: #032c2d;

  @include focus-within {
    background-color: #d0af75;
  }
}

// HOVER STATE

@media #{$large-up} {
  .featured-games__hidden {
    display: none;
    position: absolute;
  }

  .featured-games__item {
    width: 33.3333%;
    background: transparent;

    @include focus-within {
      background-color: #fff;
      z-index: 21;
      transform: scale(1.025);

      .featured-games__image {
        transform: scale(0.95);
        border: none;
      }

      .featured-games__platform-tag-wrap {
        text-align: left;
        color: #032c2d;
      }

      .featured-games__hidden {
        display: block;
        z-index: 22;
        top: 99%;
      }
    }
  }
}
