@keyframes fade {
  0%   { opacity:1; }
  45%  { opacity:0; }
  55%  { opacity:0; }
  100% { opacity:1; }
}

.amiibo__cards {
  display: -ms-flexbox;
  display: -webkit-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  -webkit-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.amiibo__cardcontainer {
  max-width: 280px;
  min-width: 100px;
  padding: 10px;
  width: 50%;
  position: relative;
  z-index: 1;
  &:nth-of-type(3n + 1) {

  }
}
.amiibo__card {
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid rgba(185,150,88,0.2);
  background-color: get-color('shadow-20');
  position: relative;
  transition: all 0.8s ease;
  z-index: 2;
  padding: 50% 10px 100%;

  &:hover,
  &:focus {
    transform: scale(1.025);
    transition: all 0.3s ease;
    background-color: rgba(108,155,173,0.1);
    border: 1px solid rgba(185,150,88,0.7);
    .amiibo__card__triforce {
      border-color: transparent transparent rgba(185, 150, 88, 0.7) transparent;
      &::before, &::after {
        border-color: transparent transparent rgba(185, 150, 88, 0.7) transparent;
      }
    }
    .hover {opacity: 1}
  }
  img {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .name {
    @include font-Bitter();
    color: get-color('white');
    font-size: rem-calc(15px);
    font-weight: 800;
    letter-spacing: 0.5px;
    position: absolute;
    bottom: 7%;
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%);
  }
  &__triforce {
    border-color: transparent transparent rgba(185, 150, 88, 0.2) transparent;
    transition: all 0.3s ease;
    &::before,
    &::after {
      border-color: transparent transparent rgba(185, 150, 88, 0.2) transparent;
      transition: all 0.3s ease;
    }
  }
}

@media #{$small-only} {
  .amiibo__card {
    img {
      //top: 40%;
    }
  }
}

@media #{$medium-only} {
  .amiibo__cardcontainer {
    &:nth-of-type(2n){
      @include slide-effect(false)
    }
  }
}
@media #{$large-up} {
  .amiibo__cardcontainer {
    width: 25%;
    &:nth-of-type(1) {
      @include slide-effect--offset();
    }
    &:nth-of-type(4n + 4){
      @include slide-effect--offset(false);
    }
    &:nth-of-type(4n + 3) {
      @include slide-effect(false);
    }
  }
  .amiibo__cards {
    //justify-content: space-around;
  }
}


