.page-videos{
  main {
    position: relative;
  }
  .hero {
    position: relative;
    &:before {
      $img: 'videos/bg-hyrule-crest.png';
      background-image: image-url($img);
    }
    .logo span {
      &:before,
      &:after {
        $img:'decorators/sword_decorator.png';
        background-size: 80%;
        height:image-height($img) * 0.8;
        margin-top:(image-height($img)/2 * -1) * 0.8;
        width:image-width($img) * 0.8;
      }
      &:before {
        right:101%;
      }
    }
  }
  .section--cards {
    background:rgba(0,0,0,.2);
    position: relative;
    &:before {
      content:none;
    }
    .triforce-border {
      display: none;
    }
  }

  .cards {
    &.vp-on-30 {
      .card-wrap {
        opacity: 1;
        transform:translate(0,0);
      }
    }
  }
  .card-wrap {
    opacity: 0;
    transition:opacity .2s ease, transform .25s ease;
    flex-basis: 100%;

    @media (min-width: 640px) {
      flex-basis: 50%;
    }

    @media #{$large-up} {
      flex-basis: 33.33333%;
    }
  }
}

@media #{$medium-up} {
  .page-videos {
    .section--cards {
      padding:70px 0 47px 0;
      &:before {
        content:'';
      }
      .triforce-border {
        display: block;
      }
    }
  }
}
@media #{$medium-only} {
  .page-videos {
    .card-wrap {
      transform:translate(-40%,0);
      &:nth-of-type(2n+2) {
        transform:translate(40%,0);
      }
    }
  }
}
@media #{$large-up} {
  .page-videos {
    .card-wrap {
      transform:translate(-40%,0);
      &:nth-of-type(3n+2) {
        transform:translate(0,30%);
      }
      &:nth-of-type(3n+3) {
        transform:translate(40%,0);
      }
    }
  }
}
