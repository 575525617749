.reduce-motion-bar {
  background: black;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 30;
}

.reduce-motion {
  background: none;
  font-family: Roboto, "Open Sans", sans-serif;
  font-size: 16px;
  color: #b99658;
  letter-spacing: 2px;
  text-transform: uppercase;
  appearance: none;
  border: none;
  padding: 2px 12px;

  @media #{$reduce-motion-query} {
    display: none;
  }
}

.reduce-motion__label {
  align-items: center;
  display: flex;

  .icon {
    margin-left: 10px;
  }
}

.reduce-motion__label--off {
  display: none;
}

@include reduce-motion() {
  .reduce-motion__label--off {
    display: flex;
  }

  .reduce-motion__label--on {
    display: none;
  }
}
