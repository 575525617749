keyframes dropin {
  0% {
    transform: translateY(-120%);
  }

  100% {
    transform: translateY(0);
  }
}

keyframes hover {
  0% {
    transform: translateY(-40px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-40px);
  }
}

keyframes rubberband {
  $factor : 0.9;

  0% { transform: scale(1); }
  30% { transform: scaleX(1.25*$factor) scaleY(0.75/$factor); }
  40% { transform: scaleX(0.75/$factor) scaleY(1.25*$factor); }
  60% { transform: scaleX(1.15*$factor) scaleY(0.85/$factor); }
  100% { transform: scale(1); }
}

keyframes wobble {
  0% { transform: translateX(0%); }
  15% { transform: translateX(-25%) rotate(-5deg); }
  30% { transform: translateX(20%) rotate(3deg); }
  45% { transform: translateX(-15%) rotate(-3deg); }
  60% { transform: translateX(10%) rotate(2deg); }
  75% { transform: translateX(-5%) rotate(-1deg); }
  100% { transform: translateX(0%); }
}

keyframes chracterHover {
  0% {
    transform: translate(0px, -40px) rotate(-3deg);
  }

  20% {
    transform: translate(5px, -30px) rotate(0deg);
  }

  40% {
    transform: translate(-5px, -30px) rotate(3deg);
  }

  50% {
    transform: translate(-5px, -20px) rotate(0deg);
  }

  60% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  60% {
    transform: translate(-4px, 10px) rotate(-2deg);
  }

  70% {
    transform: translate(-4px, 13px) rotate(2deg);
  }

  80% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  90% {
    transform: translate(5px, 0px) rotate(-3deg);
  }

  100% {
    transform: translate(-2px, -15px) rotate(0deg);
  }
}

@keyframes flashImage
{
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(3);
  }
  100% {
    filter: brightness(1);
  }
}
