body.news-modal-open {
  overflow: hidden;
}

// universal modal class resets:
.modal--news {
  z-index: 120;
  .modal__wrapper {
    height: 100%;
    max-width: none;
    padding: 0;
    top: 0;
    transform: translate(-50%, 0);
  }
  
  .modal__close {
    position: fixed;
    right: 32px;
    top: 62px;
    z-index: 10;
  }
  
  .modal__content {
    background: none; // this will likely change.
    height: 100%;
    overflow-x: hidden;
    padding: 0;
  }
}

// the actual content, as injected by the JS:

.news-modal-inner {
  background-image: image-url("common/pattern-triforce-2x.png"),
  linear-gradient(to bottom, #023326 0%, #053335 50%, #0a1928 100%);
  background-position: center center;
  background-repeat: repeat, no-repeat;
  background-size: 2.5rem, cover;
  max-width: none;

  .news-article--header {
    height: 80px;
    width: 100%;
  }

  .news-article--img__container {
    margin-top: 40px;
    position: relative;
    img {
      border: 1px solid #F4EFE5;
    }
    .news-article-wc__header-image {
      margin-top: 40px;
      border: 4px solid #F4EFE5;
    }
  }

  .triforce-border {
    display: block;
  }

  .news-article--content__container {
    padding-bottom: 48px;

  }

  .news-article--content {
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: relative;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.4em;
    margin: 1.5em 0 0.65em 0;
  }

  h1.news-article-title {
    font-size: 28px;
    // margin-top: 1.4em !important;
    text-align: center;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
    color: #B99658;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 18px;
  }

  hr {
    border: 1px solid #b99658;
    margin: 1em;
  }

  .news-article-wc__date {
    color: #B99658;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.4em;
  }
  .news-article-wc__body a,
  .news-article-wc__disclaimers a {
    color: #7DA0A5;
    text-decoration: underline;
    transition: color 0.15s ease-in;
  }

  .news-article-wc__body a:hover, .news-article-wc__disclaimers a:hover {
    color: #F4EFE5;
  }

  .news-article-wc__body p, .news-article-wc__disclaimers p {
    @include font-Roboto();
    font-style: normal;
    line-height: 1.6em;
  }

  .news-article-wc__body p {
    font-size: 16px;
    margin-block-start: 1.5em; 
    margin-block-end: 1.5em;
  }

  .news-article-wc__disclaimers p {
    font-size: 14px;
  }

  .news-article-wc__body ul, .news-article-wc__body ol {
    line-height: 1.6em;
    color: #B99658;
    font-size: 16px;
    p {
      color: #F4EFE5; 
    }
  }
}

@media #{$medium-up} {
  .news-modal-inner {
    .news-article--header {
      height: 124px;
    }
    .news-article--img__container {
      margin-top: 32px;    
      img {
        padding-top: 0;
      }
    }
  }
}

@media #{$large-up} {
  .news-modal-inner {
    .news-article--header {
      height: 264px;
    }
    .news-article--img__container {
      margin-top: -10%;
    }
  }

}