.nav-toggle {
  display: none;
}

.nav-toggle__label {
  display: inline-block;
  color:get-color('white');
  align-self: center;

}
.nav-toggle__label-open {
  display: none;
}

#nav-toggle:checked ~ .nav-toggle__label {
  .nav-toggle__label-open {
    display: block;
  }
  .nav-toggle__label-closed {
    display: none;
  }
}

#nav-toggle:checked ~ .nav-zelda-container {
  opacity: 1;
  visibility: visible;
}


.nav-zelda {
  background: rgba(0,0,0,0.6);
  position: absolute;
  top:0;
  z-index:99;
  height:47px;
  width: 100%;
  display: flex;
  padding:10px;
  justify-content: flex-end;
}
.nav-zelda-logo {
  display: block;
  position: absolute;
  left:10px;
  margin-top:-17px;
  top:50%;
  img {
    width:auto;
    height:34px;
  }

}
.nav-zelda-container {
  opacity: 0;
  visibility: hidden;
  background: rgba(0,0,0,0.8);
  transition:opacity .3s ease, visibility .3s ease;
  position: absolute;
  padding:10px 15px;
  left:0;
  top:100%;
  width:100%;
}

.nav-zelda-main {
  @include font-Roboto();
  color: get-color('white');
  font-size:1rem;
  list-style: none;
  letter-spacing: 2px;
  margin: 0;
  padding:0;
  text-align: center;
}

.nav-zelda-main__link {
  $img: 'decorators/sub_nav_text_decorator.svg';
  padding:10px;
  cursor: pointer;
  .link {
    display: inline-block;
    padding:0 24px;
    position: relative;
    text-decoration: none;
    span {
      opacity: 0.8;
    }
    &::before,
    &::after {
      background: image-url($img) no-repeat center center;
      content: '';
      height: image-height($img);
      margin-top: image-height($img)/2 * -1;
      opacity: 0;
      position: absolute;
      top: 50%;
      transition: opacity 0.3s ease, transform 0.3s ease;
      width: image-width($img);
    }
    &::before {
      left:0;
      transform: translateX(-11px) scaleX(-1);
    }
    &::after {
      right:0;
      transform: translateX(11px);
    }

    &:hover,
    &.is-active {
      span {
        opacity: 1.0;
      }
      &::before,
      &::after {
        opacity: 1.0;
        transition-delay: 0.2s, 0;
      }
      &::before {
        transform: translateX(0) scaleX(-1);
      }
      &::after {
        transform:translateX(0);
      }
    }
  }
}

// @media #{$small-only} {
  .nav-sticky .nav-zelda {
    position: fixed;
  }
// }

@media #{$medium-up} {
  .nav-toggle__label {
    display: none;
  }
  #nav-toggle ~ .nav-zelda-container {
    opacity: 1;
    visibility: visible;
    transition:none;
  }
  .nav-zelda {
    background:none;
    padding:0;
  }
  .nav-zelda-logo {
    display: none;
  }
  .nav-zelda-container {
    background: rgba(0,0,0,0.6);
    padding:0;
    position: static;
  }
  .nav-zelda-main {
    display: flex;

    flex-flow: row nowrap;
    justify-content: center;
  }
  .nav-zelda-main__link {
    padding:14px 12px 13px 12px;
  }
}