@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

html {
  background-image: image-url("common/pattern-triforce-2x.png"),
    linear-gradient(to bottom, #023326 0%, #053335 50%, #0a1928 100%);
  background-position: center center;
  background-repeat: repeat, no-repeat;
  background-size: 2.5rem, cover;
  height: auto;
  min-height: 100%;
}
#wrap {
  overflow: hidden;
}
.page-home {
  //HERO
  .home-hero {
    text-align: center;
    $img: "home/zelda-portal-hero-small.jpg";
    background: image-url($img) top center no-repeat;
    background-size: cover;
    top: -47px; //compensate for navbar sitting attop
    box-shadow: 0 15px 75px 0 get-color("black");
    position: relative;

    &::after {
      $img: "home/hyrule_rule.png";
      background: image-url($img) bottom center;
      height: image-height($img);
      content: "";
      width: 100%;
      position: absolute;
      bottom: -44px;
      left: 0;
      margin-top: 100%;
      margin-top: image-height($img) / 2;
      z-index: 2;
    }
    .home-hero__video-container {
      height: 100%;
      width: 100%;
      overflow: hidden;
      display: none;
      position: relative;
    }

    // .home-hero__video{
    //   object-fit: cover;
    //   width: 100%;
    //   height: 100%;
    //   position: relative;
    //   top: 0;
    //   left: 0;
    // }

    .home-hero__video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
    }

    .home-hero__overlay {
      padding-top: 120px;
      opacity: 1;
      background: linear-gradient(rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.6));
    }
    .hero__header {
      position: relative;
      text-align: center;
      background: rgba(0, 0, 0, 0);
    }
    .hero__video-text {
      @include font-Bitter();
      font-size: 2.5rem;
      font-style: italic;
      left: 50%;
      position: absolute;
      text-shadow: 0 1px 2px get-color("black");
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 2s ease-out;
    }
    .hero__links {
      padding-top: 2%;
      padding-bottom: 5%;
      ul {
        list-style: none;
        padding-left: 0;
      }
      .column-flex-large-4 {
        padding: 0;
      }

      .hero__button {
        display: inline-block;
        position: relative;
        line-height: 1.4;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        padding-bottom: 20px;
        span {
          display: block;
          font-size: 1.0833333333em;
          font-weight: 700;
          font-style: normal;
        }
      }
    }
  }

  //WORLD INFO
  .world-info {
    padding: calc(3.5rem - 43px) 0 2.44rem;
    .time {
      color: get-color("grey-blue");
      font-family: $font-main;
      font-style: italic;
      font-size: 1.25rem;
      display: block;
      margin-bottom: 1rem;
    }
    .section-copy {
      width: 100%;
    }
  }

  //FEATURE
  .feature {
    padding: 27px 1rem 39px;
    position: relative;
    background-color: get-color("shadow-20");

    .feature-container {
      border: 2px solid get-color("gold");
      max-height: 515px;
      max-width: 914px;

      button {
        display: block;
        padding: 0;
        border: none;

        &:hover .ring,
        &:focus .ring {
          -webkit-animation: rotating 4s linear infinite;
          -moz-animation: rotating 4s linear infinite;
          -ms-animation: rotating 4s linear infinite;
          -o-animation: rotating 4s linear infinite;
          animation: rotating 4s linear infinite;
        }
      }
    }

    .play-button {
      cursor: pointer;
      background-color: rgba(55, 113, 121, 0.9);
      border-radius: 50%;
      height: 100px;
      left: calc(50% - 46px);
      padding: 4px;
      position: absolute;
      top: calc(50% - 46px);
      width: 100px;
      .ring {
        width: 100%;
        height: 100%;
      }
      .play-triangle {
        position: absolute;
        top: calc(50% - 20.5px);
        left: calc(50% - 10.5px);
        filter: drop-shadow(0 0 4px get-color("shadow-50"));
      }
    }
  }
  .feature__video {
    display: block;
    cursor: pointer;
    width: 100%;
    height: auto;
  }
  .feature__text {
    font-family: $font-secondary;
    color: get-color("grey-blue");
    text-align: center;
    p {
      font-style: normal;
      font-size: 1.125rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .featured-cta {
    margin: 2rem auto 0;
    position: relative;
    text-align: center;
    width: 92%;
    max-width: 466px;
    z-index: 2;
    color: #f4efe5;
  }

  //NEWS GRID

  .news--feature-text {
    margin-bottom: 3.375rem;
  }

  .section--cards {
    padding-bottom: 6rem;
  }

  .news-cta {
    margin: 2rem auto 0;
    position: relative;
    text-align: center;
    width: 92%;
    max-width: 466px;
    z-index: 2;
  }

  //GAMES
  .games {
    padding: 4.625rem 0;
    &__content {
      &__header {
        width: 99%;
        margin: auto;
        margin-bottom: 3.375rem;
      }
      &__game-links {
        position: relative;
        font-size: 1.125rem;
        margin-top: 2rem;

        @media #{$medium-up} {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          @media #{$medium-up} {
            display: flex;
            align-items: center;

            li {
              display: flex;
              align-items: center;
            }
          }
        }

        .platform-links {
          display: block;
          font-family: $font-secondary;
          color: get-color("grey-blue");
          margin: 0;

          @media #{$large-up} {
            margin-right: 10px;
          }
        }

        .link-slash {
          display: none;

          @media #{$medium-up} {
            display: inline-block;
          }
        }

        .link {
          white-space: nowrap;
          display: inline-block;
          font-family: $font-main;
          text-decoration: none;
          color: get-color("white");
          transition-duration: 0.3s;
          transition-property: transform;
          transform: translateZ(0);
          text-shadow: 0 2px 4px get-color("shadow-50");
          margin-bottom: 10px;
          margin-top: 10px;
          font-weight: bold;
          display: block;

          &:hover,
          &:focus {
            color: get-color("gold");
            transform: translateY(-3px);

            &:after {
              transform: translateY(3px);
              color: #fff;
            }
          }

          &:after {
            font-weight: normal;
            position: relative;
            right: -15px;
            display: inline-block;
            transition-duration: 0.3s;
          }

          @media #{$large-up} {
            padding-right: 5px;
            padding-left: 25px;
            display: inline-block;
          }
        }
        span {
          color: get-color("grey-blue");
          padding: 0 0.5rem;
        }
      }
    }

    .link_play {
      text-decoration: none;
    }
  }

  //AMIIBO
  .amiibo {
    padding-bottom: 6rem;
  }
  .amiibo--feature-text {
    position: relative;
    margin-bottom: 3.375rem;
  }
  .amiibo__content .amiibo-cta {
    margin: 2rem auto 0;
    position: relative;
    text-align: center;
    width: 92%;
    max-width: 466px;
    z-index: 2;
    span.sm {
      display: none;
    }
    @media #{$small-only} {
      span.sm {
        display: block;
      }
      span.lg {
        display: none;
      }
    }
  }

  //PLAY
  .play {
    position: relative;
    background-color: get-color("grey-blue");
    background-image: linear-gradient(
      to right,
      #000000 0%,
      #193c38 25%,
      #193c38 75%,
      #000000 100%
    );
    padding: 2rem 0 0.5rem 0;

    .play__content__feature-image-group {
      position: relative;
      max-width: 350px;
      margin: -40px auto -25px;
      width: 100%;
      img {
        max-width: 100%;
      }
      .play-hero-link {
        margin-left: -20%;
        width: 81.57894737%;
        position: relative;
        z-index: 1;
        transition-delay: 0.2s;
      }
      .play-hero-zelda {
        width: 84.21052632%;
        position: absolute;
        left: 22%;
        top: -50px;
        transition-delay: 0.4s;
      }
      .hover {
        opacity: 1;
        height: 130%;
        width: 250%;
        z-index: -1;
      }

      @media screen and (min-width: 61.5em) {
        width: 30%;
      }
    }

    .play__container {
      width: 100%;

      @media screen and (min-width: 61.5em) {
        width: 70%;
      }
    }

    .playframe__frame {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 210%;

      iframe {
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @media only screen and (min-width: 44.5em) {
      .playframe__frame {
        padding-bottom: percentage(411/738);
        max-width: 990px;
        margin: 0 auto;
      }
    }
  }

  //NEWS VIDEOS
  .news-videos {
    padding-top: 74px;
    padding-bottom: 0px;
    .header-ornament {
      margin-bottom: 35px;
    }
    .cards-more {
      .cta-btn {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .news-list {
      padding: 0;
      .cards-more {
        .cta-btn {
          max-width: 532px;
        }
      }
    }
    .videos-list {
      padding-top: 74px;
      .card {
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
      .cards-more {
        width: 100%;
        .cta-btn {
          max-width: 320px;
        }
      }
    }
  }
  nclood-nav {
    .news {
      margin: 0 0.5em 0 0 !important;
    }
  }
  //NEWS
  .news {
    margin-top: 5rem;
    margin-bottom: 6rem;

    .row {
      padding: 0;
    }

    h3 {
      margin-bottom: 3rem;
    }

    .cta-button {
      margin-top: 2rem;
    }
  }
}

@media #{$small-only} {
  .page-home {
    .home-hero {
      background-position: center 46px;
      background-size: 110%;

      .home-hero__overlay {
        background: linear-gradient(rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.6));
      }
    }

    .hero {
      &__content {
        .content-text {
          p {
            font-size: rem-calc(18px);
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media #{$xsmall-only} {
  .page-home {
    .news-videos {
      .videos-list {
        .card {
          width: 100%;
        }
      }
    }
  }
}

@media #{$medium-only} {
  .page-home {
    .home-hero {
      background-position: center 46px;
      background-size: 110%;

      .home-hero__overlay {
        background: linear-gradient(rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.6));
      }
    }
  }
}

@media #{$large-up} {
  .page-home {
    .home-hero {
      background: #0e111f;
      height: 700px;

      $img: "home/zelda-portal-hero.png";
      background: image-url($img) top center no-repeat;

      .fade-out {
        opacity: 0;
      }
      &.is-resizing {
        .hero__video-text,
        .home-hero__overlay {
          transition: none;
        }
      }
    }

    .home-hero__video-container {
      height: 700px;
    }

    .hero__video-text {
      transition: opacity 2s ease-in;
    }

    .home-hero .home-hero__video-container {
      display: block;
    }
    .home-hero .home-hero__overlay {
      padding-top: 150px;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      left: 0;
      transition: opacity 2s ease-in;
    }
    .hero__links {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-bottom: 90px;

      ul {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        list-style: none;
      }
    }
    .hero__button {
      padding-bottom: 0;
    }
    .play {
      .play__content__feature-image-group {
        margin-top: 0;
        margin-bottom: 0;
        max-width: 33.33333%;
      }
      .play__content__play-tiles {
        padding-top: 25px;
      }
    }
    .news-videos {
      .videos-list {
        position: relative;
        padding-top: 0;
        .card {
          width: 100%;
        }
        .card-container {
          // padding:0 0px;
        }
        .cards-more {
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
    .hero {
      &__content {
        .content-text {
          .header-copy {
            position: relative;
            width: 70%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      &__buttons__button {
        margin: 1rem 7% 0;
      }
    }
    .feature {
      h3 {
        font-size: 1.625rem;
      }
      &__text {
        p {
          width: 80%;
        }
      }
    }
    .play {
      &__feature-img {
        //left: calc(-5% + -73px);
        //top: -30%;
      }
      &__feature-text {
        margin-left: calc(20% + 110px);
      }
    }
    .world-info {
      .row-flex {
        align-items: center;
      }
    }
  }
}

@media #{$xlarge-up} {
  .page-home {
    .home-hero {
      height: 800px;
      .home-hero__video {
        // height: 800px;
      }
    }
  }
}

@include reduce-motion {
  .page-home {
    .feature {
      .feature-container {
        button {
          &:hover,
          &:focus {
            .ring {
              animation: none;
            }
          }
        }
      }
    }
  }
}
