$colors: (
  'orange': #ff8c00,
  'green': #14be14,
  'red': #ff4123,
  'blue': #3264e6,
  'blue-light': #afebff,
  'yellow': #ffd700,
  'gray': #505050,
  'purple': #cd5aff,

  //CUSTOM
  'white': #F4EFE5,
  'grey-blue': #7DA1A5,
  'teal': #42736E,
  'light-gold': #EEC965,
  'gold': #B99658,
  'dark-gold': #86724E,
  'dark-green': #193C38,
  'light-green': #7DA0A5,
  'light-grey': #B8B8B8,
  'dark-grey': #4A4A4A,
  'dark-blue': #1B2E40,
  'black': #000000,
  'facebook': #5883dc,
  'beige': #B99F65,
  'shadow-20': rgba(0,0,0,0.2),
  'shadow-50': rgba(0,0,0,0.5)
);
