@mixin reduce-motion() {
  @include reduce-motion-query {
    @content;
  }

  @include reduce-motion-class {
    @content;
  }
}

@mixin reduce-motion-query {
  @media #{$reduce-motion-query} {
    @content;
  }
}

@mixin reduce-motion-class {
  .is-reduced-motion {
    @content;
  }
}

@function get-color($color) {
  @return map-get($colors, $color);
}

@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin center-absolute($trans, $img: "", $retina: true) {
  position: absolute;
  top: 50%;
  left: 50%;

  @if $trans {
    transform: translate(-50%, -50%);
  } @else {
    $div: 2;

    @if $retina {
      $div: 4;
    }

    margin-left: -(image-width($img) / $div);
    margin-top: -(image-height($img) / $div);
  }
}

@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(
    legacy-direction($direction),
    $color-stops
  );
  background: linear-gradient($direction, $color-stops);
}

@mixin slide-effect($side: true) {
  transition: transform 0.6s cubic-bezier(0.46, 0.09, 0.38, 1.02),
    opacity 0.4s cubic-bezier(0.46, 0.09, 0.38, 1.02);
  opacity: 0;

  @if $side {
    transform: translateX(-50%);
  } @else {
    transform: translateX(50%);
  }
}

@mixin slide-effect-in() {
  transform: translateX(0);
  opacity: 1;
}

@mixin slide-effect--offset($side: true) {
  transition: transform 0.6s cubic-bezier(0.46, 0.09, 0.38, 1.02) 0.3s,
    opacity 0.4s cubic-bezier(0.46, 0.09, 0.38, 1.02) 0.3s;
  opacity: 0;

  @if $side {
    transform: translateX(-50%);
  } @else {
    transform: translateX(50%);
  }
}

@mixin focus-within {
  &:hover {
    @content;
  }

  &:focus-within {
    @content;
  }
}
