.character-filters-wrapper {
  &.row {
    margin-top: 3.3rem;
    margin-bottom: 20px;
  }
  .character-filters {
    text-align: center;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      $img: "about/glossary_icon.png";
      @include font-Kadwa();
      border: 1px solid get-color("gold");
      color: get-color("white");
      padding: 0.5rem 2.5rem;
      position: relative;
      margin: 0 auto 2rem;
      max-width: 280px;
      transition: all 1s ease;
      width: 80%;

      @media #{$medium-up} {
        display: inline-block;
        margin-left: 25px;
        margin-right: 25px;
        width: auto;
      }

      &::before,
      &::after {
        background-image: image-url($img);
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        height: 32px;
        opacity: 0;
        position: absolute;
        top: 50%;
        transition: transform 0.3s, opacity 0.2s;
        width: 28px;
        pointer-events: none;
      }

      &::before {
        left: 0;
        transform: translate(-200%, -50%);
      }

      &::after {
        background-image: image-url("about/glossary_icon_right.png");
        right: 0;
        transform: scaleX(-1) translate(200%, -50%);
      }

      @include focus-within {
        background-color: rgba(27, 63, 60, 0.5);
        transition: all 0.3s ease;
      }

      &.active {
        background-color: rgba(27, 63, 60, 0.9);

        &::before,
        &::after {
          opacity: 1;
        }

        &::before {
          transform: translate(-85%, -50%);
        }

        &::after {
          transform: translate(85%, -50%);
        }
      }

      button {
        @include font-Kadwa();

        box-shadow: none;
        color: map-get($colors, white);
        display: inline-block;
        font-size: rem-calc(20px);
        min-width: 152px;
        padding: 3px 20px 1px;
        text-decoration: none;
        border: none;
        background: none;

        @media #{$medium-up} {
          font-size: rem-calc(20px);
          margin: -7px -46px;
          min-width: 0;
          padding: 9px 68px 7px;
        }

        @media #{$large-up} {
          font-size: rem-calc(20px);
        }
      }
    }
  }
}

.characters-list {
  list-style: none;
  padding: 0;

  @media #{$small-only}, #{$medium-only} {
    @include display-flex(flex);
    @include flex-wrap(wrap);
    @include justify-content(center);
  }

  .character {
    @include align-self(flex-end);
    margin-bottom: 12px;
    text-align: center;
    transition: all 0.3s ease-in-out;

    img {
      background: linear-gradient(
        to bottom,
        rgba(27, 63, 60, 0) 0%,
        rgba(27, 63, 60, 0.5) 100%
      );
    }

    @media #{$small-only} {
      @include flex-basis(31%);
      margin: 0 1% 2%;
      max-width: 31%;
      width: 31%;
    }

    @media #{$medium-only} {
      @include flex-basis(23%);
      margin: 0 1% 2%;
      max-width: 23%;
      width: 23%;
    }

    button {
      background: none;
      padding: 0;
      border: none;
      display: block;
      position: relative;
      margin: 0 auto;
      top: 0;
      transition: top 0.2s ease-in-out;

      &:hover,
      &:focus {
        top: -7px;

        img {
          animation: flashImage 0.2s ease-in-out;
          background: image-url("gallery-assets/glossary-bg.png") center center
            no-repeat;
          background-size: cover;
        }
      }

      img {
        max-width: 100%;
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
      }
    }
  }

  &[data-show-group="characters"] {
    .character {
      &[data-group="places"],
      &[data-group="items"] {
        display: none;
      }
    }
  }

  &[data-show-group="places"] {
    .character {
      &[data-group="characters"],
      &[data-group="items"] {
        display: none;
      }
    }
  }

  &[data-show-group="items"] {
    .character {
      &[data-group="characters"],
      &[data-group="places"] {
        display: none;
      }
    }
  }
}

@include reduce-motion {
  .characters-list {
    .character {
      button {
        &:hover,
        &:focus {
          top: 0;

          img {
            animation: none;
          }
        }
      }
    }
  }
}
