.triforce {
  &.vp-on-30 {
    .triforce--switcher--symbol,
    .triforce--switcher--characters {
      transform: translateX(0);
    }
  }
  .triforce--detail {
    position: relative;
  }
  .triforce--mobile-nav {
    position: absolute;
    width: 100%;
    height: 65px;
    left: 0;
    top: 25%;
    margin-top: -32.5px;
    z-index: 2;
    @media #{$large-up} {
      display: none;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      position: absolute;
      display: block;
      top: 0;
      width: 49px;
      height: 65px;

      &.triforce--mobile-nav-prev {
        left: 10px;

        @media #{$medium-only} {
          left: 30px;
        }
      }

      &.triforce--mobile-nav-next {
        right: 10px;

        @media #{$medium-only} {
          right: 30px;
        }
      }
    }
  }

  .triforce--switcher {
    display: flex;
    align-items: stretch;
    flex-direction: column-reverse;

    @media #{$large-up} {
      flex-direction: row;
    }
  }
  .triforce--switcher--symbol {
    transition: transform 0.6s ease;
    transform: translateX(-140%);
    @media #{$medium-only} {
      margin-top: 20px;
    }
    @media #{$large-up} {
      order: 1;
      flex-basis: 50%;
      width: 50%;
      max-width: 50%;
      padding: 0 55px;
    }
  }
  .triforce--switcher--characters {
    transition: transform 0.6s ease;
    transform: translateX(140%);
    @media #{$large-up} {
      flex-basis: 50%;
      order: 2;
      max-width: 50%;
      padding: 0 10px;
      width: 50%;
    }
  }

  .triforce-symbol {
    position: absolute;
    $img: "triforce/triforce-symbol.png";
    background: image-url($img) no-repeat left top;
    background-size: 100%;
    height: 80px;
    width: 92px;
    bottom: 101%;
    left: 50%;
    margin-left: -46px;
    @media #{$small-only} {
      height: 60.9px;
      width: 70px;
      margin: 0;
      bottom: 41%;
      left: 75%;
    }
    @media #{$medium-up} {
      bottom: 35%;
      left: auto;
      right: 20px;
    }
    @media #{$large-up} {
      position: relative;
      bottom: auto;
      right: auto;
      width: 100%;
      height: 0;
      padding-bottom: 86.6388309%;
      margin-left: auto;
      margin-right: auto;
    }

    button {
      padding: 0;
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.5s ease-in;
      text-indent: -999em;
      @media #{$large-up} {
        display: block;
      }

      &.current,
      &:hover,
      &:focus {
        opacity: 1;
      }

      &[data-triforce="wisdom"] {
        $img: "triforce/triforce-wisdom-sprite.png";
        width: 50%;
        padding-bottom: 43.333333335%;
        height: 0;
        top: 50%;
        left: 0;
        background: image-url($img) no-repeat left top;
        background-size: 100%;
        background-position: left top;
      }

      &[data-triforce="courage"] {
        $img: "triforce/triforce-courage-sprite.png";
        width: 50%;
        padding-bottom: 43.333333335%;
        height: 0;
        top: 50%;
        left: auto;
        right: 0;
        background: image-url($img) no-repeat left top;
        background-size: 100%;
        background-position: left top;
      }

      &[data-triforce="power"] {
        $img: "triforce/triforce-power-sprite.png";
        width: 50.3131524%;
        height: 55%;
        top: 1px;
        left: 50%;
        transform: translateX(-50%);
        background: image-url($img) no-repeat left top;
        background-size: 100%;
        background-position: left top;
      }
    }
  }
  .triforce--detail {
    margin-top: 40px;
    @media #{$small-only} {
      margin-bottom: 0;
    }
  }
  .character-container {
    position: relative;
    width: 100%;
    height: 400px;

    .hover {
      height: 120%;
      opacity: 1;
      width: 50%;
    }
    @media #{$small-only} {
      height: 322px;
    }

    @media #{$medium-up} {
      height: 500px;
    }
    @media #{$large-up} {
      height: 100%;
      display: flex;
      .hover {
        width: 140%;
      }
    }
  }
  .character {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    margin: 0;

    .character-img {
      opacity: 0;
      transition: all 0.3s ease-in;
      display: block;
      width: 270px;
      margin-left: auto;
      margin-right: auto;
      @media #{$medium-up} {
        width: auto;
        max-width: 477px;

        img {
          display: block;
          margin: auto;
        }
      }

      img {
        max-width: 100%;
      }
    }
    figcaption {
      @include font-Roboto();

      font-size: rem-calc(12px);
      color: white;
      display: block;
      padding-top: 10px;
      opacity: 0;
      position: absolute;
      bottom: -60px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      overflow: hidden;

      @media #{$large-up} {
        bottom: -40px;
      }
    }
  }
  .meta-container {
    position: relative;
    width: 100%;
    min-height: 170px;
    @media #{$large-up} {
      min-height: 210px;
      padding-bottom: 20px;
    }
  }
  .meta {
    position: absolute;
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: all 0.3s ease-in;

    h3 {
      @include font-Bitter();
      color: get-color("gold");
      font-style: italic;
      font-weight: normal;
      font-size: rem-calc(24px);
      line-height: 1.5;
      margin-bottom: 11px;
      span {
        @include font-Bitter();
        display: block;
        font-weight: bold;
        font-style: normal;
        font-size: 1.75em;
        line-height: 1.2380952381;
        text-transform: uppercase;
        margin-top: -8px;
      }
    }
    p {
      margin-bottom: 12px;
      font-size: rem-calc(18px);
      max-width: 420px;
      margin-left: auto;
      margin-right: auto;
      @media #{$small-only} {
        font-size: 1rem;
      }
    }
    @media #{$large-up} {
      margin-top: 20px;
    }
  }

  &[data-triforce="wisdom"] {
    .triforce-symbol {
      button[data-triforce="wisdom"] {
        opacity: 1;
        background-position: left bottom;
        transition: opacity 0.5s ease-out;
      }
    }

    .character.wisdom {
      .character-img,
      figcaption {
        opacity: 1;
        transition: all 0.5s ease-out;
      }
    }
    .meta.wisdom {
      opacity: 1;
      transition: all 0.5s ease-out;
    }
  }

  &[data-triforce="courage"] {
    .triforce-symbol {
      button[data-triforce="courage"] {
        opacity: 1;
        background-position: left bottom;
        transition: opacity 0.5s ease-out;
      }
    }

    .character.courage {
      .character-img,
      figcaption {
        opacity: 1;
        transition: all 0.5s ease-out;
      }
    }

    .meta.courage {
      opacity: 1;
      transition: all 0.5s ease-out;
    }
  }

  &[data-triforce="power"] {
    .triforce-symbol {
      button[data-triforce="power"] {
        opacity: 1;
        background-position: left bottom;
        transition: opacity 0.5s ease-out;
      }
    }

    .character.power {
      .character-img,
      figcaption {
        opacity: 1;
        transition: all 0.5s ease-out;
      }
    }

    .meta.power {
      opacity: 1;
      transition: all 0.5s ease-out;
    }
  }
}
