.cta-btn {
  @include font-Bitter();

  font-weight: 400;
  font-size: 1.25rem;
  color: get-color('white');
  position: relative;
  $img-l: 'decorators/cta_button_left.png';
  $img-r: 'decorators/cta_button_right.png';
  $border-w:image-width($img-l)/2;
  display: block;
  width:100%;
  span {
    display: block;
    border-top: 4px solid #b99047;
    border-bottom: 4px solid #b99047;
    text-align: center;
    margin:0 $border-w;
    transition: color 1.0s ease;
    line-height: 1;
    padding:8px 0;
  }
  &::before,
  &::after {
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
    width: image-width($img-l)/2;
    height: image-height($img-l)/2;
    position: absolute;
    top: 0;
  }
  &::before {
    left:0;
    background-image: image-url($img-l);
  }
  &::after {
    right:0;
    background-image: image-url($img-r);
  }
  &:hover,
  &:focus {
   span {
     color: get-color('light-gold');
     transition: color 0.3s ease;
   }
  }
}
a.cta-btn {
  text-decoration: none;
}


.button-ornament {
  $img: 'home/button_decoration_left.png';

  position: relative;
  padding-left:54px;
  padding-right:54px;
  &::before,
  &::after {
    content: '';
    transition: transform 1.0s ease;
    width: 36px;
    height: 54px;
    position: absolute;
    top: 50%;
    margin-top:-27px;
  }
  &::before {
    background: image-url($img);
    left:0;
  }
  &::after {
    background: image-url('home/button_decoration_right.png');
    right:0;
  }
  &:hover,
  &:focus {
    &::before {
      transform:translateX(-.5em);
      transition-duration: 0.25s;
    }
    &::after {
      transform:translateX(.5em);
      transition-duration: 0.25s;
    }
  }
}
