.icon--arrow-left
{
	position: relative;
  display: inline-block;
	background: image-url('about/icon--arrow-left.png') no-repeat;
	background-size: contain;
	width: 49px;
	height: 65px;
}

.icon--arrow-right
{
	position: relative;
  display: inline-block;
	background: image-url('about/icon--arrow-right.png') no-repeat;
	background-size: contain;
	width: 49px;
	height: 65px;
}

.icon--grid
{
	display: inline-block;
	background: image-url('about/icon--grid.png') no-repeat;
	background-size: contain;
	width: 20px;
	height: 20px;
	position: relative;
	top: 3px;
	left: -3px;
}
