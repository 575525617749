@mixin retina-image($image, $factor: 0.5, $bgImage: false) {
  $width : image-width($image)*$factor;
  $height : image-height($image)*$factor;

  background: image-url($image) center center no-repeat;

  @if $bgImage {
    background-size: $width $height;
    min-height: $height;
  } @else {
    background-size: contain;
    display: inline-block;
    height: $height;
    width: $width;
  }
}

@mixin retina-image-refactor($image, $factor: 0.5) {
  $width : image-width($image)*$factor;
  $height : image-height($image)*$factor;

  height: $height;
  width: $width;
}

@mixin image-replace() {
  text-indent: 110%;
  white-space: pre;
  overflow: hidden;
}

@mixin background-image($image,$factor:0.5) {
  $width : image-width($image)*$factor;
  $height : image-height($image)*$factor;
  background-image:image-url($image);
  background-size: $width $height;
}
