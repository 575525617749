.slide-effect {
  @include slide-effect()
}

.slide-effect--right {
  @include slide-effect(false);
}

.slide-effect--offset {
  @include slide-effect--offset()
}

.slide-effect--offset--right {
  @include slide-effect--offset(false);
}

.slide-effect--center {
  transform: translateX(0);
}

.vp-on-30 {
  .slide-effect {
    @include slide-effect-in();
  }
}



@include reduce-motion() {
  .slide-effect {
    transition-property: opacity;
  }
}
