@import url("https://fonts.googleapis.com/css?family=Lato:400,400i");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
@import url("https://fonts.googleapis.com/css?family=Bitter:400,400i,700");
@import url("https://fonts.googleapis.com/css?family=Kadwa:400,700");
@import url("https://fonts.googleapis.com/css?family=gBitter%7CCinzel:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato|Viga");

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 12px;
  color: #000000;
}

body {
  @include font-Bitter();
  @include font-smoothing();
  color: get-color("white");

  line-height: 1.27;
}

p {
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.5555555556;
  margin-bottom: 0.8em;
  em,
  i {
    font-style: none;
  }
}

em,
i {
  font-style: italic;
}

.modal,
#site-wrap {
  a,
  button,
  input,
  textarea,
  [role="button"] {
    &:focus {
      box-shadow: 0px 0px 5px 5px #7da0a5, inset 0px 0px 5px 5px #7da0a5;
      outline: dashed #1b2e40;
    }
  }
}

// Helpers
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

span.nobr {
  white-space: nowrap;
}

.font-fallback {
  @include font-Lato();

  transform: translateY(-0.5em);
  font-size: 0.5em;
  display: inline-block;
}

.text-center {
  text-align: center;
}

@each $color, $hex in $colors {
  .text-#{$color} {
    color: $hex;
  }
}

.text-shadow {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
}

.text-upper {
  text-transform: uppercase;
}

.img-block {
  display: block;
}

.img-full {
  display: block;
  width: 100%;
}

/* Anchors */
a,
a:visited {
  font-family: inherit;
  color: inherit;
}

/* Headers */
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  @include font-Bitter();
  margin: 0.65em 0 0.45em 0;

  &:first-child {
    margin-top: 0;
  }
}

h1,
.h1 {
  font-size: 3.5rem;
}

h2,
.h2 {
  font-size: 3rem;
}

h3,
.h3 {
  // font-size: 2.25rem;
  font-size: 1.625rem;
  font-weight: 700;
  text-shadow: 0 1px 2px get-color("shadow-50");
}

h4,
.h4 {
  // font-size: 1.375rem;
  // font-weight: bold;
  font-size: 1.25rem;
  font-weight: 700;
}

h5,
.h5 {
  font-size: 1rem;
  font-weight: bold;
}

.header-copy,
.section-title,
.section-copy {
  @include font-Bitter();
  color: get-color("white");
  text-align: center;
}

.header-copy {
  font-size: rem-calc(18px);
  font-style: italic;
  padding: 0 10px;
  text-shadow: 0 1px 2px get-color("black");
  line-height: 1.6em;
  @media #{$medium-up} {
    font-size: 1.25rem;
  }
  @media #{$large-up} {
    font-size: rem-calc(22px);
  }
  @media #{$xlarge-up} {
    font-size: rem-calc(24px);
  }
}

.section-title {
  font-size: rem-calc(26px);
  font-weight: 700;
  text-shadow: 0 1px 2px get-color("shadow-50");
}
.section-copy {
  font-size: rem-calc(18px);
  font-style: italic;
  line-height: 1.6em;
  padding: 0 10px;
  @media #{$small-only} {
    font-size: 1rem;
  }
  @media #{$medium-only} {
    font-size: 1rem;
  }
}

@media #{$medium-up} {
  html {
    font-size: 14px;
  }

  p {
    font-size: 1.2rem;
  }
}

@media #{$large-up} {
  html {
    font-size: 16px;
  }

  p {
    font-size: 1rem;
  }
}

/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .esrb-rating img[src$=".svg"] {
    width: 100%;
    max-width: 136px;
  }
}
