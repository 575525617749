.page-tears {
  $img: "tears/Background1.jpg";
  background: image-url($img);
  
  .reduce-motion-bar, header nav {
    display: none;
  }
  section.main {
    background: image-url('tears/keyArt_desktop.jpg') #000;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .content {
      opacity: 0;
      color: #2B2420;
      padding: 20rem 2rem 2rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      h3.glow {
        display: inline-block;
        padding: .25rem 2rem;
        background: rgba(255, 255, 255, .3);
      }
      @media (max-width: 768px) {
        padding: 12rem 2rem 2rem 2rem;
        flex-direction: column;
        align-items: center;
      }
      .title {
        max-width: 444px;
        img {
          max-width: 100%;
        }
      }
      .thumbnail {
        text-align: center;
        button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: none;
          background: none;
        }
        li {
          flex-basis: unset;
          width: 100%;
          max-width: 100%;
        }
        img {
          max-width: 25rem;
          transition: .3s ease-in-out;
          &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 10px 3px #B99658;
          }
        }
      }
    }
    @media(max-width: 768px) {
      background: image-url('tears/keyArt_tablet_mobile.jpg');
      background-position: center;
      background-size: cover;
    }
  }

  section.date {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    button {
      background: none;
      border: none;
    }
    img {
      margin: 1rem auto;
      max-width: 100%;
    }
  }

  .footer-main {
    position: relative;
    background-color: #18473d;
    margin-top: 2rem;
    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 26px;
      $img: "tears/green-deco_2x.png";
      background: image-url($img);
      background-size: contain;
      background-position: bottom;
      background-repeat: repeat-x;

    }
  }
  .fade {
    transition: .6s ease-in;
    opacity: 1 !important;
  }
  .modal__wrapper {
    overflow: unset;
    position: absolute;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .video-container {
      max-width: 900px;
      max-height: 506.25px;
    }
    .modal__content {
      padding: 0;
    }
    .modal__close {
      top: -23px;
      right: -23px;
    }
  }
  .desktop {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

}