.facebook {
  position: relative;
  background-color: get-color("dark-blue");
  background-image: linear-gradient(
    to right,
    #000000 0%,
    get-color("dark-blue") 25%,
    get-color("dark-blue") 75%,
    #000000 100%
  );
  max-height: 141px;
  margin-top: 8.5rem;
  min-height: 110px;

  &__feature-img {
    display: none;
    z-index: 1;
    .image-container {
      position: relative;
      &:hover {
        .hover {
          opacity: 1;
        }
      }
      img {
        position: relative;
        margin-top: -4.5rem;
        z-index: 1;
      }
    }
  }
  &__feature-text {
    transition: all 1s ease;
    padding-top: 6px;
    //margin-top: 1.5rem;
    font-size: rem-calc(22px) !important;
    //padding-bottom: 2rem;
    position: absolute;
    left: 50%;
    top: calc(50% - 13.5px);
    transform: translate(-50%, -50%);

    &::before,
    &::after {
      $img: "decorators/corner_decorator_left.png";
      background: image-url($img) center center no-repeat;
      display: inline-block;
      content: "";
      width: image-width($img);
      height: image-height($img);
      position: absolute;
      top: 0;
    }
    &::before {
      left: -4rem;
      transition: all 1s ease;
    }
    &::after {
      right: -4rem;
      background-image: image-url("decorators/corner_decorator_right.png");
      transition: all 1s ease;
    }
    &:hover {
      &::before {
        left: -2.5rem;
        transition: all 0.3s ease;
      }
      &::after {
        right: -2.5rem;
        transition: all 0.3s ease;
      }
    }
    a {
      text-decoration: none;
      padding-left: 0.28em;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .hover {
    height: 130%;
    width: 130%;
  }
}
@media #{$small-only} {
  .facebook__feature-text {
    font-size: rem-calc(20px) !important;
    width: 100%;
  }
}
@media #{$large-up} {
  .facebook__feature-img {
    display: inline-block;
  }
  .facebook__feature-text {
    margin-top: 2.5rem;
    position: relative;
    transition: all 1s ease;
    padding-top: 12px;
    font-size: rem-calc(22px) !important;
    padding-bottom: 2rem;
    left: 0;
    top: 0;
    transform: translate(0, 0);
  }
}

@media #{$xlarge-up} {
  .facebook__feature-text {
    //font-size: 1.625rem;
    font-size: inherit;
  }
}
