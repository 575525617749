.medium-up,
.medium-only,
.large-up,
.large-only,
.xlarge-up,
.xlarge-only,
.xxlarge-up,
.xxlarge-only
{
  display: none;
}

@media #{$medium-up} {
  .small-only {
    display: none;
  }

  .medium-up {
    display: block;
  }
}

@media #{$medium-only} {
  .medium-only {
    display: block;
  }
}

@media #{$large-up} {
  .large-up {
    display: block;
  }
}

@media #{$large-only} {
  .large-only {
    display: block;
  }
}

@media #{$xlarge-up} {
  .xlarge-up {
    display: block;
  }
}

@media #{$xxlarge-up} {
  .xxlarge-up {
    display: block;
  }
}
