.page-news {
  main {
    position: relative;
  }
  .hero {
    &:before {
      $img: "news/bg-img-hyrule.png";
      background-image: image-url($img);
      background-position: center top;
    }
    .logo span {
      &:before,
      &:after {
        $img: "decorators/sword_decorator.png";
        background-size: 80%;
        height: image-height($img) * 0.8;
        margin-top: (image-height($img) / 2 * -1) * 0.8;
        width: image-width($img) * 0.8;
      }
      &:before {
        right: 101%;
      }
    }
  }
  .hero__image__wrapper {
    img {
      margin-left: -8%;
      margin-bottom: -17%;
    }
  }

  .cards {
    padding-bottom: 40px;
  }

  .cta-btn {
    background: transparent;
    width: fit-content;
    border: none;
    margin: auto;
    min-width: MIN(400px, 85%);
    padding: 0;

    &:hover {
      cursor: pointer;
    }

    span {
      margin: 0 25.5px;
      padding: 8px 32px;
    }
  }

  .section--cards {
    background: rgba(0, 0, 0, 0.2);
    padding: 0 16px 56px;
    position: relative;

    &:before {
      content: none;
    }
    .triforce-border {
      display: none;
    }
  }
  .cards {
    &.vp-on-30 {
      .card-wrap {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
  .card-wrap {
    opacity: 0;
    transition: opacity 0.2s ease, transform 0.25s ease;
    flex-basis: 100%;

    @media (min-width: 640px) {
      flex-basis: 50%;
    }

    @media #{$large-up} {
      flex-basis: 33.33333%;
    }
  }

  .load-more--hidden,
  .news-card--hidden {
    display: none;
  }
}

@media #{$medium-up} {
  .page-news {
    .section--cards {
      padding-top: 70px;

      &:before {
        content: "";
      }
      .triforce-border {
        display: block;
      }
    }
  }
}

@media #{$medium-only} {
  .page-news {
    .logo {
      span {
        font-size: 0.85rem;
      }
    }
    .card-wrap {
      transform: translate(-40%, 0);
      &:nth-of-type(2n + 2) {
        transform: translate(40%, 0);
      }
    }
  }
}

@media #{$large-up} {
  .page-news {
    .card-wrap {
      transform: translate(-40%, 0);
      &:nth-of-type(3n + 2) {
        transform: translate(0, 30%);
      }
      &:nth-of-type(3n + 3) {
        transform: translate(40%, 0);
      }
    }
  }
}
