.skippers {
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  height: 0;
  color: #f4efe5;
  overflow: hidden;

  @include focus-within {
    height: auto;
    padding: 5px;
  }
}

.skippers__item {
  &:hover,
  &:focus {
    opacity: 1;
  }

  .icon {
    margin-left: 10px;
  }
}
